import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/event1.jpg'
import sImg2 from '../../images/event/event2.jpg'
import sImg3 from '../../images/event/event3.jpg'
import LocationMap from '../Modal'


const Events = [
    {
        Simg: sImg1,
        title:'THE WEDDING',
        li1:'Wednesday, 1 March 2023 ',
        li2:'Philippine Embassy, Doha Qatar',
    },
    {
        Simg: sImg2,
        title:'THE RECEPTION',
        li1:'Thursday, 2 March 2023 6:30 PM',
        li2:'',
    },
    {
        Simg: sImg3,
        title:'ZOOM MEETING',
        li1:'Thursday, 2 March 2023',
        li2:'Jacob & Jefhie Wedding Reception ',
        li3:'6:30pm - Qatar Time',
        li4:'11:30pm - Philippine Time ',
        li5:'Zoom ID: 210 427 6811 ',
        li6:'Passcode: JJP020323'
    },

]

const EventSection = (props) => {
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container">
                <SectionTitle MainTitle={'TIME AND PLACE'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-img">
                                        <img src={event.Simg} alt=""/>
                                    </div>
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.li3}</li>
                                            <li>{event.li4}</li>
                                            <li>{event.li5}</li>
                                            <li>{event.li6}</li>

                                            {/* {event.title === "THE RECEPTION" &&
                                            <li><LocationMap/></li>
                                            } */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;