import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import coupleImg1 from '../../images/couple/1.png'
 
class CoupleSection extends Component {

    render() {
        return(
            <section className="couple-section section-padding" id="couple">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xs-12">
                            <div className="couple-area clearfix">
                                <div className="text-grid bride">
                                    <h3>Jacob Ponciano</h3>
                                    <p>I value spending time with friends and loved ones. I also have a passion for movies and enjoy relaxing with a good film. As an introverted person, I appreciate quiet and introspective moments, which allow me to recharge and reflect.</p>
                                    <div className="social">
                                        <ul>
                                          
                                            <li><a href="https://www.instagram.com/jdponcianoo/"><i className="ti-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={coupleImg1} alt=""/>
                                    </div>
                                </div>
                                <div className="text-grid groom">
                                    <h3>Jefhie Gungob</h3>
                                    <p> I have a passion for exploring the great outdoors and trying my hand at new DIY projects and recipes. Growing up surrounded by nature has given me a deep appreciation for the beauty and simplicity of life, and I find peace and solace in spending time in the natural world.</p>
                                    <div className="social">
                                        <ul>
                                            {/* <li><Link to="/"><i className="ti-facebook"></i></Link></li> */}
                                            {/* <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li> */}
                                            <li><a href="https://www.instagram.com/febjefh/"><i className="ti-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        );
    }
}
 
export default CoupleSection;